import React from "react";
import { ISvgProps } from "./interface";

export default function SearchSvg({
  width = "30",
  height = "30",
  className,
}: ISvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle
        cx="11.0913"
        cy="11.0918"
        r="7.00035"
        transform="rotate(-45 11.0913 11.0918)"
        fill="white"
        stroke="#919191"
        strokeWidth="1.55563"
      />
      <line
        x1="16.2245"
        y1="16.2251"
        x2="22.8245"
        y2="22.8251"
        stroke="#919191"
        strokeWidth="2.07418"
        strokeLinecap="round"
      />
    </svg>
  );
}
