import React from "react";
import { createRoot } from "react-dom/client";
import Routes from "./routes";
import "./styles/index.css";

const rootElement = document.getElementById("root");

// a simple check if root exist or not
if (!rootElement) throw new Error("Failed to find the root element");

const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>
);
