import React, { useEffect, useState, useRef } from "react";
import { notification } from "antd";
import PackageJson from "../../../package.json";

// components
import LoanCard from "../LoanCard";

// elements
import SearchFilter from "../../elements/SearchFilter";

// services
import getLoans from "../../services/loan/getLoans";
import { useLoan } from "../../contexts/loan-context";

function Sidebar() {
  const { setLoan, loan: globalLoanID } = useLoan();
  const { version } = PackageJson;

  const [loanID, setLoanID] = useState("");
  const [page, setPage] = useState(1);

  const { listOfLoans, error, currResult, loadMore } = getLoans(loanID, page);
  const loader = useRef(null);

  if (error) {
    notification.error({
      message: "Error while fetching loans!",
    });
  }

  useEffect(() => {
    if (page > 0) {
      loadMore();
    }
  }, [page, loanID]);

  const handleObserver = (entries: any) => {
    const target = entries[0];

    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (currResult?.length > 0) {
      setLoan(currResult[0]?.supportLoanNumber);
    } else if (listOfLoans) {
      setLoan(listOfLoans[0]?.supportLoanNumber);
    }
  }, [listOfLoans, currResult]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);

    if (loader.current) observer.observe(loader.current);

    return () => {
      if (loader.current) observer.unobserve(loader.current);
    };
  }, [loader]);

  return (
    <div className="w-[250px] h-[calc(100vh-72px)] bg-white border-r border-[#D2D2D2]">
      <SearchFilter
        searchedFilter={loanID || ""}
        onFilter={(loanId: string, clearValue: boolean) => {
          setLoanID(clearValue ? "" : loanId);
          setPage(1);
        }}
      />
      <div className="overflow-y-scroll h-[calc(100vh-72px-36px)]">
        {loanID?.length > 0 &&
          currResult?.length > 0 &&
          currResult?.map((loan) => (
            <LoanCard
              key={loan?.supportLoanNumber}
              supportLoanNumber={loan?.supportLoanNumber}
              selected={loan?.supportLoanNumber === globalLoanID}
              loanAmount={`₹ ${loan?.loanAmount?.toLocaleString()}`}
              createdAt={loan?.createdAt}
              loanType={loan?.loanType}
              onClick={(loanId: string) => {
                setLoan(loanId);
              }}
            />
          ))}
        {loanID?.length > 0 && currResult?.length === 0 && (
          <p>No loans found</p>
        )}
        {loanID?.length === 0 &&
          listOfLoans?.map((loan) => (
            <LoanCard
              key={loan?.supportLoanNumber}
              supportLoanNumber={loan?.supportLoanNumber}
              selected={loan?.supportLoanNumber === globalLoanID}
              loanAmount={`₹ ${loan?.loanAmount?.toLocaleString()}`}
              createdAt={loan?.createdAt}
              loanType={loan?.loanType}
              onClick={(loanId: string) => {
                setLoan(loanId);
              }}
            />
          ))}
        <div ref={loader} />
      </div>
      <p className="absolute bottom-4 flex items-center justify-center w-[57px] font-extrabold left-3 text-[12px] h-[24px] px-[10px] py-[4px] border border-[#E6E6E6] rounded-lg bg-[#FFFFFF] text-[#909090]">
        V{version}
      </p>
    </div>
  );
}

export default Sidebar;
