import { PING_RESOURCE_URL, REQUEST_TIMEOUT } from "./constants";

export const timeoutUnresolvedRequest = (
  time: number,
  promise: Promise<unknown>
) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error("Request timed out."));
    }, time);

    promise.then(resolve, reject);
  });
};

export function secondsToMinutes(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return `${String(minutes).padStart(2, "0")}:${String(
    remainingSeconds
  ).padStart(2, "0")}`;
}

export const checkInternetConnection = async () => {
  const controller = new AbortController();
  const { signal } = controller;

  if (!navigator?.onLine) {
    return false;
  }

  try {
    await timeoutUnresolvedRequest(
      REQUEST_TIMEOUT,
      fetch(PING_RESOURCE_URL, {
        method: "GET",
        signal,
      })
    );

    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    controller.abort();
  }

  return false;
};

export const delay = (time: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, time);
  });

export const dateFormatter = (date: any) =>
  `${new Date(date).toLocaleDateString()?.replaceAll("/", "-")} ${new Date(
    date
  )?.toLocaleTimeString([], {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
  })}`;

export const getTitleFromLoanState: any = {
  PENDING_OROCORP_APPROVAL: "Pending Orocorp Approval",
  OROCORP_APPROVED: "Orocorp Approved",
  OROCORP_REJECTED: "Orocorp Rejected",
  CST_APPROVED: "CST Approved",
  CST_REJECTED: "CST Rejected",
  BLC_VERIFIED: "BLC Verified",
  BLC_ABORTED: "BLC Aborted",
  BLV_CONFIRMED: "BLV Confirmed",
  BLV_ABORTED: "BLV Aborted",
  GOLD_STORED: "Gold Stored",
  GOLD_RELEASED: "Gold Released",
  LOAN_AMOUNT_TRANSFERRED: "Loan Amount Transferred",
  LOAN_RENEWED: "Loan Renewed",
  LOAN_AUCTIONED: "Loan Auctioned",
  RENEWAL_REQUESTED: "Renewal Requested",
  RENEWAL_PENDING_FUND_TRANSFER: "Renewal Pending fund transfer",
  LOAN_ACCOUNT_CLOSURE_INITIATED: "Loan Account Closure Initiated",
  LOAN_CLOSED: "Loan Closed",
};
