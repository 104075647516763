import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getValueFromLocalStorage } from "../utils/local-storage";

interface IPrivateRoutesProps {
  children: JSX.Element;
}

export default function PrivateRoutes({
  children,
}: IPrivateRoutesProps): JSX.Element {
  // fetch token from localstorage
  const TOKEN = getValueFromLocalStorage("token");
  const pathname = useLocation()?.pathname;

  // check if token and user data exist then render the component
  // else redirect to login
  return TOKEN ? (
    children
  ) : (
    <Navigate
      to={{ pathname: "/" }}
      state={{
        pathname,
      }}
    />
  );
}
