import React from "react";

// utils
import {
  INetworkStatusProviderProps,
  INetworkStatusContext,
} from "./interfaces";
import { checkInternetConnection } from "../../utils/helper";
import { NETWORK_CHECK_POLLING_TIME } from "../../utils/constants";

const NetworkStatusContext = React.createContext<INetworkStatusContext>({
  isOnline: true,
});

export function NetworkStatusProvider({
  children,
}: INetworkStatusProviderProps) {
  const [isOnline, setIsOnline] = React.useState<boolean>(true);
  const value = React.useMemo(() => ({ isOnline }), [isOnline]);

  const checkConnection = async () => {
    const status = await checkInternetConnection();
    setIsOnline(status);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };

  React.useEffect(() => {
    window.addEventListener("offline", handleOffline);

    const checkConnectionInterval = setInterval(() => {
      checkConnection();
    }, NETWORK_CHECK_POLLING_TIME);

    return () => {
      window.removeEventListener("offline", handleOffline);
      clearInterval(checkConnectionInterval);
    };
  }, []);

  return (
    <NetworkStatusContext.Provider value={value}>
      {children}
    </NetworkStatusContext.Provider>
  );
}

export function useNetworkStatus() {
  const context = React.useContext(NetworkStatusContext);

  if (context === undefined) {
    throw new Error(
      "useNetworkStatus must be used within NetworkStatusProvider"
    );
  }

  return context;
}
