import React from "react";
import { ICountContext, ICountProviderProps } from "./interfaces";

const LoanContext = React.createContext<ICountContext>({
  loan: "",
  setLoan: () => {},
});

export function LoanProvider({ children }: ICountProviderProps) {
  const [loan, setLoan] = React.useState<string>("");
  const value = React.useMemo(() => ({ loan, setLoan }), [loan]);

  return <LoanContext.Provider value={value}>{children}</LoanContext.Provider>;
}

export function useLoan() {
  const context = React.useContext(LoanContext);

  if (context === undefined) {
    throw new Error("useLoan must be used within a CountProvider");
  }

  return context;
}
