import { useState, useEffect } from "react";
import uniqBy from "lodash.uniqby";
import { IBRLLoanDetails } from "../../interfaces/loan";
import useAxios from "../../lib/initAxios";
import { getValueFromLocalStorage } from "../../utils/local-storage";

function getBRLLoans(loanID: string, page: number) {
  const [currResult, setCurrentResult] = useState<Array<IBRLLoanDetails>>([]);
  const [listOfBrlLoans, setLoanData] = useState<Array<IBRLLoanDetails>>([]);
  const [{ data: firstPageData }] = useAxios({
    url: "/api/v1/loan/brl/search",
    method: "GET",
    headers: {
      Authorization: `JWT ${getValueFromLocalStorage("token")}`,
      "Content-Type": "application/json",
    },
    params: {
      search: "",
      page: 1,
    },
  });
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: "/api/v1/loan/brl/search",
      method: "GET",
      headers: {
        Authorization: `JWT ${getValueFromLocalStorage("token")}`,
        "Content-Type": "application/json",
      },
      params: {
        search: loanID,
        page: loanID?.length > 0 ? 1 : page,
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (data) {
      setLoanData((prev) =>
        uniqBy(
          [
            ...(firstPageData?.data || []),
            ...(prev || []),
            ...(data?.data || []),
          ]?.sort((a, b) =>
            // eslint-disable-next-line no-nested-ternary
            a.createdAt > b.createdAt ? -1 : b.createdAt > a.createdAt ? 1 : 0
          ),
          "loanNumber"
        )
      );

      setCurrentResult(loanID?.length > 0 ? data?.data : []);
    }
  }, [data, page]);

  return {
    listOfBrlLoans,
    loadMoreBrlLoan: execute,
    error,
    loading,
    currResult,
  };
}

export default getBRLLoans;
