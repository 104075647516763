/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";

import { ILoanDetails } from "../../interfaces/loan";
import { dateFormatter } from "../../utils/helper";

function LoanCard({
  supportLoanNumber,
  loanType,
  loanAmount,
  createdAt,
  selected = false,
  onClick,
}: ILoanDetails) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={`${
        selected ? "bg-[#f7c366]" : "hover:bg-amber-50"
      } flex flex-col space-y-2 py-6 px-4 justify-start border-b border-[#D2D2D2]`}
      onClick={() => {
        onClick(supportLoanNumber);
      }}
    >
      <p className="font-semibold text-base leading=[22px]">
        Loan ID: {supportLoanNumber}
      </p>
      <div className="flex items-center space-x-2">
        <div className="text-[10px] leading-[14px] bg-[#F0B859] border-[0.5px] border-[#464646] rounded-[3px] py-[0.5px] px-2">
          {loanType === "Support Loan" ? "SL" : "BRL"}
        </div>
        <p className="text-[10px] leading-[14px] bg-[#F0B859] border-[0.5px] border-[#464646] rounded-[3px] py-[0.5px] px-2">
          {loanAmount}
        </p>
      </div>
      <p className="text-xs opacity-25 uppercase">{dateFormatter(createdAt)}</p>
    </div>
  );
}

export default LoanCard;
