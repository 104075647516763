import { useState, useEffect } from "react";
import uniqBy from "lodash.uniqby";
import { ILoanDetails } from "../../interfaces/loan";
import useAxios from "../../lib/initAxios";
import { getValueFromLocalStorage } from "../../utils/local-storage";

function getLoans(loanID: string, page: number) {
  const [currResult, setCurrentResult] = useState<Array<ILoanDetails>>([]);
  const [listOfLoans, setLoanData] = useState<Array<ILoanDetails>>([]);
  const [{ data: firstPageData }] = useAxios({
    url: "/api/v1/loan/search",
    method: "POST",
    headers: {
      Authorization: `JWT ${getValueFromLocalStorage("token")}`,
      "Content-Type": "application/json",
    },
    data: {
      search: "",
      page: 1,
    },
  });
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: "/api/v1/loan/search",
      method: "POST",
      headers: {
        Authorization: `JWT ${getValueFromLocalStorage("token")}`,
        "Content-Type": "application/json",
      },
      data: {
        search: loanID,
        page: loanID?.length > 0 ? 1 : page,
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (data) {
      setLoanData((prev) =>
        uniqBy(
          [
            ...(firstPageData?.data || []),
            ...(prev || []),
            ...(data?.data || []),
          ]?.sort((a, b) =>
            // eslint-disable-next-line no-nested-ternary
            a.createdAt > b.createdAt ? -1 : b.createdAt > a.createdAt ? 1 : 0
          ),
          "supportLoanNumber"
        )
      );

      setCurrentResult(loanID?.length > 0 ? data?.data : []);
    }
  }, [data, page]);

  return { listOfLoans, loadMore: execute, error, loading, currResult };
}

export default getLoans;
