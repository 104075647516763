import React from "react";

// assets
import CrossIconSvg from "../../assets/svgs/crossIconSVG";
import SearchSvg from "../../assets/svgs/searchIconSVG";

// interface
interface ISearchFilterProps {
  searchedFilter: string;
  // eslint-disable-next-line react/require-default-props
  placeHolder?: string;
  onFilter: (loanID: string, clearValue: boolean) => void;
}

export default function SearchFilter({
  searchedFilter,
  placeHolder = "Search using Loan ID",
  onFilter,
}: ISearchFilterProps) {
  return (
    <div className="bg-white border border-secondary-grey w-full flex items-center">
      <input
        type="text"
        placeholder={placeHolder}
        className="py-2 px-3 text-sm font-bold outline-none w-full"
        value={searchedFilter}
        onChange={(event) => {
          onFilter(event.target.value, false);
        }}
      />
      <div className="pr-1">
        {searchedFilter ? (
          <CrossIconSvg
            className="cursor-pointer h-3"
            onClick={() => onFilter("", true)}
          />
        ) : (
          <SearchSvg className="h-6" />
        )}
      </div>
    </div>
  );
}
