import { deleteAllCookies } from "./cookies";
import {
  addToLocalStorage,
  clearAllLocalStorage,
  getValueFromLocalStorage,
} from "./local-storage";

const AuthUtils = {
  getToken() {
    return getValueFromLocalStorage("token");
  },
  getRefreshToken() {
    return getValueFromLocalStorage("refreshToken");
  },
  setToken(token: any) {
    addToLocalStorage("token", token);
  },
  storeAuthTokens(data: any) {
    addToLocalStorage("token", data?.token);
    addToLocalStorage("refreshToken", data?.refreshToken);
  },
  logout() {
    deleteAllCookies();
    clearAllLocalStorage();
    window.location.reload();
  },
  isLoggedIn() {
    if (
      !!getValueFromLocalStorage("token") &&
      !!getValueFromLocalStorage("refreshToken")
    )
      return true;
    return false;
  },
};

export default AuthUtils;
